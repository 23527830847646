import { isNumberEven } from "@utils/misc";

export const getSectionVariant = (index: number) => {
  if (isNumberEven(index)) {
    return "light";
  }
  return "grey";
};

export const getSectionGridItemVariant = (index: number) => {
  if (isNumberEven(index)) {
    return "black";
  }
  return "white";
};
